import {request,requestJSON} from '../request.js'


/**
 * 查询小程序所有设置
 * @param data
 * @returns {*}
 * @author
 */
export const getMiniProSetting = (data)=> {
    return request({
        method: 'post',
        url: '/merchant/setting/getMiniProSetting',
        data: data
    })
};
/**
 * 批量更新设置
 * @param data
 * @returns {*}
 * @author
 */
export const updateMiniProSettingBatch = (data)=> {
    return requestJSON({
        method: 'post',
        url: '/merchant/setting/updateMiniProSettingBatch',
        data: data
    })
};