<template>
    <div class="table_box">
        设置
<!--            <div class="table_box_content">-->
<!--                <el-row align="middle" type="flex" class="table-title">-->
<!--                <el-col style="text-align: left">-->
<!--                    <div class="keyword">-->
<!--                        <span>{{$t("m.setting.dianpushezhi")}}</span>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <div>-->
<!--                <el-form label-width="180px">-->
<!--                    <el-form-item :label="$t('m.setting.label.gouwuche')">-->
<!--                        <el-radio-group v-model="form.shopping.value" >-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.kaiqi')}}</el-radio>-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.guanbi')}}</el-radio>-->
<!--                        </el-radio-group>-->
<!--                        <div class="form-tips">-->
<!--                            {{$t('m.setting.formTips.gouwuhche')}}-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.lianxikefu')">-->
<!--                        <el-radio-group v-model="form.contact.value" @change="handleContact">-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.kaiqi')}}</el-radio>-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.guanbi')}}</el-radio>-->
<!--                            <el-popover-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>使用帮助</div>-->
<!--                                <span slot="reference" class="hrefTips">{{$t('m.setting.form.bangzhu')}}</span>-->
<!--                            </el-popover>-->
<!--                        </el-radio-group>-->
<!--                        <div class="form-tips">-->
<!--                            {{$t('m.setting.formTips.lainxikefu')}}-->
<!--                        </div>-->
<!--                        <el-checkbox v-model="form.productDetal.value" :true-label="1" :false-label="0" style="margin-top: 20px;" >{{$t('m.setting.form.shanpinxiangqin')}}</el-checkbox>-->
<!--                        <br/>-->
<!--                        <el-radio-group v-model="form.productCustom.value" style="display: flex;margin-top: 10px;">-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.moren')}}</el-radio>-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.zidingyi')}}</el-radio>-->
<!--                            <el-input size="small" :placeholder="$t('m.setting.formTips.rule')" v-if="form.productCustom.value==1"-->
<!--                                      class="inputClass" v-model="form.productCustom.name" maxlength="4"></el-input>-->
<!--                        </el-radio-group>-->
<!--                        <div class="form-tips" style="margin-top: 10px;">{{$t('m.setting.formTips.zixunguwen')}}-->
<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.chakanshili')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>示例</div>-->
<!--                                <span slot="reference" class="hrefTips" style="margin-left: 32px;">{{$t('m.setting.form.chakanshili')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                        <el-checkbox style="margin-top: 20px;" :true-label="1" :false-label="0" v-model="form.orderDetail.value">{{$t('m.setting.form.dingdanxiangqin')}}</el-checkbox>-->
<!--                        <br/>-->
<!--                        <el-radio-group v-model="form.orderDetailName.value" style="display: flex;margin-top: 10px;">-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.moren')}}</el-radio>-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.zidingyi')}}</el-radio>-->
<!--                            <el-input size="small" :placeholder="$t('m.setting.formTips.rule')" v-if="form.orderDetailName.value==1"-->
<!--                                      class="inputClass" v-model="form.orderDetailName.name" maxlength="4"></el-input>-->
<!--                        </el-radio-group>-->
<!--                        <div class="form-tips" style="margin-top: 10px;">{{$t('m.setting.formTips.zixunguwen')}}-->
<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.chakanshili')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>示例</div>-->
<!--                                <span slot="reference" class="hrefTips" style="margin-left: 32px;">{{$t('m.setting.form.chakanshili')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.dianpudibu')">-->
<!--                        <el-radio-group v-model="form.bottomLogo.value" class="flexRadio">-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.morentupian')}}</el-radio>-->
<!--                            <div v-if="form.bottomLogo.value==0"  class="editor_goods_upload_box defalut-icon">-->
<!--                                <img src="../../assets/edit-imgs/making/default-icon.png"/>-->
<!--                            </div>-->
<!--&lt;!&ndash;                            <img class="editor_goods_upload_box" style="margin-right: 30px;" :src="form.bottomLogo.logoUrl" />&ndash;&gt;-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.tupianzidingyi')}}</el-radio>-->
<!--                            <div v-if="form.bottomLogo.value==1" style="display: flex;align-items: center">-->
<!--                                <div class="editor_goods_upload_box hover" v-if="form.bottomLogo.value==1&&form.bottomLogo.logoUrl!=''"-->
<!--                                     :style="{backgroundImage: 'url('+form.bottomLogo.logoUrl+')'}"-->
<!--                                     style="background-size:contain;background-position:center;">-->
<!--                                    <div class="images-delete"-->
<!--                                         @click="deleteMateria">-->
<!--                                        <i class="el-icon-delete"/>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <span class="hrefTips" @click="upload" v-if="form.bottomLogo.logoUrl==''">{{$t('m.setting.form.shangchuantupian')}}</span>-->
<!--                                <span class="hrefTips" @click="returnUpload" v-else>{{$t('m.setting.form.chongxinshezhi')}}</span>-->
<!--                            </div>-->


<!--                        </el-radio-group>-->
<!--                        <div class="form-tips" style="margin-top: 10px">{{$t('m.setting.formTips.shangchuantishi')}}</div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.shoukongshangpin')">-->
<!--                        <el-checkbox v-model="form.goodOut.value" :true-label="1" :false-label="0">{{$t('m.setting.form.zhanshi')}}</el-checkbox>-->
<!--                        <div class="form-tips">{{$t('m.setting.formTips.shoukongtishi')}}-->
<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.chakanshili')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>{{$t('m.setting.example')}}</div>-->
<!--                                <span slot="reference" class="hrefTips" style="margin-left: 32px;">{{$t('m.setting.form.chakanshili')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                </el-form>-->
<!--            </div>-->
<!--            <el-row align="middle" type="flex" class="table-title">-->
<!--                <el-col style="text-align: left">-->
<!--                    <div class="keyword">-->
<!--                        <span>{{$t("m.setting.shangpinshezhi")}}</span>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <div>-->
<!--                <el-form label-width="180px">-->
<!--                    <el-form-item :label="$t('m.setting.label.jibenshezhi')">-->
<!--                        <el-checkbox v-model="form.merchantBtn.value" :true-label="1" :false-label="0">{{$t('m.setting.form.dianpuanniu')}}</el-checkbox>-->
<!--                        <div class="form-tips">{{$t('m.setting.formTips.kuaisufanhui')}}</div>-->
<!--                        <el-checkbox v-model="form.recommended.value" :true-label="1" :false-label="0">{{$t('m.setting.form.tuijianshangpin')}}</el-checkbox>-->
<!--                        <div class="form-tips">{{$t('m.setting.formTips.tuijianshangpin')}}</div>-->
<!--&lt;!&ndash;                        <el-checkbox v-model="form.productPinjia.value" :true-label="1" :false-label="0">{{$t('m.setting.form.shangpinpinjia')}}</el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                        <br/>&ndash;&gt;-->
<!--&lt;!&ndash;                        <el-checkbox v-model="form.haopinlv.value" :true-label="1" :false-label="0">{{$t('m.setting.form.haopinlv')}}</el-checkbox>&ndash;&gt;-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.shangpinzhanshi')">-->
<!--                        <el-checkbox v-model="form.shoppingBtn.value" :true-label="1" :false-label="0">{{$t('m.setting.form.lijigoumai')}}</el-checkbox>-->
<!--                        <div class="form-tips">{{$t('m.setting.formTips.goumaianniu')}}</div>-->
<!--                        <div style="display: flex;margin-top: 20px;">-->
<!--                            <el-radio-group v-model="form.shoppingBtn.isName"  style="display: flex;" @change="handleisName">-->
<!--                                <el-radio :label="0">{{$t('m.setting.form.moren')}}</el-radio>-->
<!--                                <el-radio :label="1">{{$t('m.setting.form.zidingyi')}}</el-radio>-->
<!--                            </el-radio-group>-->
<!--                            <el-input size="small" :placeholder="$t('m.setting.formTips.rule')" v-if="form.shoppingBtn.isName==1"-->
<!--                                      class="inputClass" v-model="form.shoppingBtn.name" maxlength="4"></el-input>-->
<!--                        </div>-->
<!--                        <div class="form-tips" style="margin-top: 10px;">{{$t('m.setting.formTips.goumainame')}}</div>-->
<!--                        <div style="margin-top: 10px;">-->
<!--                            <el-checkbox v-model="form.sales.value" :true-label="1" :false-label="0">{{$t('m.setting.form.xiaoliangxinxi')}}</el-checkbox>-->
<!--                            <el-checkbox v-model="form.sales.isManySales" :true-label="1" :false-label="0">-->
<!--                                {{$t('m.setting.form.xiaoliang')}}-->
<!--                                <el-input size="small" type="Number" v-model="form.sales.sales" class="smallInput"></el-input>-->
<!--                                {{$t('m.setting.form.shizhanshi')}}-->
<!--                            </el-checkbox>-->
<!--                        </div>-->
<!--                        <el-checkbox v-model="form.transaction.value" :true-label="1" :false-label="0">{{$t('m.setting.form.chengjiaojilv')}}</el-checkbox>-->
<!--                        <div style="margin-top: 10px;">-->
<!--                            <el-checkbox v-model="form.sharemini.value" :true-label="1" :false-label="0">{{$t('m.setting.form.minifenxiang')}}</el-checkbox>-->
<!--                            <el-checkbox v-model="form.sharemini.isShowAutoPrefix" :true-label="1" :false-label="0" @change="handleAutoPrefix">-->
<!--                                {{$t('m.setting.form.zidingyiqianzhui')}}-->
<!--                            </el-checkbox>-->
<!--                            <el-input size="small" v-model="form.sharemini.prefix" class="smallInput" style="margin-left: 10px"></el-input>-->

<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.fenxiangxiaoguoshili')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>{{$t('m.setting.shareExamples')}}</div>-->
<!--                                <span slot="reference" class="hrefTips" style="margin-left: 32px;">{{$t('m.setting.form.fenxiangxiaoguoshili')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                        <div style="margin-top: 10px;">-->
<!--                            <el-checkbox v-model="form.SharingPersonalInfo.value" :true-label="1" :false-label="0">-->
<!--                                {{$t('m.setting.form.haibaofenxaing')}}-->
<!--                                {{$t('m.setting.form.haibaoyangshi')}}-->
<!--                                <el-select size="small" v-model="form.SharingPersonalInfo.style">-->
<!--                                    <el-option :value="0" :label="$t('m.setting.styleOne')"></el-option>-->
<!--                                    <el-option :value="1" :label="$t('m.setting.styleII')"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-checkbox>-->
<!--                            <el-checkbox v-model="form.SharingPersonalInfo.isSharingPersonalInfo" :true-label="1" :false-label="0">-->
<!--                                {{$t('m.setting.form.fenxiangrenxinxi')}}-->
<!--                            </el-checkbox>-->
<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.haibaoxiaoguo')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>{{$t('m.setting.posterExample')}}</div>-->
<!--                                <span slot="reference" class="hrefTips" style="margin-left: 32px;">{{$t('m.setting.form.haibaoxiaoguo')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                </el-form>-->
<!--            </div>-->
<!--            <el-row align="middle" type="flex" class="table-title">-->
<!--                <el-col style="text-align: left">-->
<!--                    <div class="keyword">-->
<!--                        <span>{{$t("m.setting.dingdanshezhi")}}</span>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <div>-->
<!--                <el-form label-width="180px">-->
<!--                    <el-form-item :label="$t('m.setting.label.shouhuodizhi')">-->
<!--                        <el-input v-model="form.selllerAddress.name" :placeholder="$t('m.setting.form.shouhuoname')" style="width: 300px;margin-right: 10px;"></el-input>-->
<!--                        <el-input v-model="form.selllerAddress.phone" :placeholder="$t('m.setting.form.shouhuophone')" style="width: 300px;margin-right: 10px;"></el-input>-->
<!--                        <el-input v-model="form.selllerAddress.address"  :placeholder="$t('m.setting.form.shouhuoaddress')" style="width: 300px;margin-right: 10px;"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.youfeishezhi')">-->
<!--                        <div>-->
<!--                            {{$t('m.setting.form.mianyou')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.beginAmount.beginAmount" class="smallInput" min="0"></el-input>-->
<!--                            {{$t('m.setting.form.bumianyou')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.beginAmount.fee" class="smallInput" min="0"></el-input>-->
<!--                            {{$t('m.setting.form.money')}}-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.daifukuandingdan')">-->
<!--                        <div>-->
<!--                            {{$t('m.setting.form.weifukuan')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.fukuanTime.value" class="smallInput" min="10" max="30"></el-input>-->
<!--                            {{$t('m.setting.form.weifukuanshijian')}}-->
<!--                        </div>-->
<!--                        <el-radio-group v-model="form.promrt.value" style="display: flex;margin-top: 20px;">-->
<!--                            <el-radio :label="1">{{$t('m.setting.form.cuifutankuang')}}</el-radio>-->
<!--                            <el-radio :label="0">{{$t('m.setting.form.guanbicuifu')}}</el-radio>-->
<!--                        </el-radio-group>-->
<!--                        <div class="form-tips" style="margin-top: 10px;">-->
<!--                            {{$t('m.setting.formTips.daifukuan')}}-->
<!--                            <el-popover-->
<!--                                    :title="$t('m.setting.form.chakanshili')"-->
<!--                                    placement="right"-->
<!--                                    width="400"-->
<!--                                    trigger="hover">-->
<!--                                <div>示例</div>-->
<!--                                <span slot="reference" class="hrefTips">{{$t('m.setting.form.chakanshili')}}</span>-->
<!--                            </el-popover>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.zidongquerenshouhuo')">-->
<!--                        <div>-->
<!--                            {{$t('m.setting.form.fahuo')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.confirmation.value" min="7" max="21" class="smallInput"></el-input>-->
<!--                            {{$t('m.setting.form.fahuohou')}}-->
<!--                        </div>-->
<!--                        <div class="form-tips" style="margin-top: 10px;"> {{$t('m.setting.formTips.zidongfukuan')}}</div>-->
<!--&lt;!&ndash;                        <div style="margin-top: 10px;">&ndash;&gt;-->
<!--&lt;!&ndash;                            {{$t('m.setting.form.tuihuo')}}&ndash;&gt;-->
<!--&lt;!&ndash;                            <el-input size="small" type="Number" v-model="form.acquirenti.value" min="7" max="21" class="smallInput"></el-input>&ndash;&gt;-->
<!--&lt;!&ndash;                            {{$t('m.setting.form.tuihuoshijian')}}&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.zidongpinlun')">-->
<!--                        <div>-->
<!--                            {{$t('m.setting.form.maijishouhuo')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.commento.value"  min="7" max="21" class="smallInput"></el-input>-->
<!--                            {{$t('m.setting.form.maijishouhuoafer')}}-->
<!--                        </div>-->
<!--                        <div class="form-tips" style="margin-top: 10px;"> {{$t('m.setting.formTips.zidongpinjia')}}</div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.tianxiewuliu')">-->
<!--                        <div>-->
<!--                            {{$t('m.setting.form.tuihuanhuo')}}-->
<!--                            <el-input size="small" type="Number" v-model="form.logistica.value" min="3" max="14" class="smallInput"></el-input>-->
<!--                            {{$t('m.setting.form.tuihuanhuoafter')}}-->
<!--                        </div>-->
<!--                        <div class="form-tips" style="margin-top: 10px;"> {{$t('m.setting.formTips.zidongguanbiliucheng')}}</div>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item :label="$t('m.setting.label.shouhoutime')">-->
<!--                        <div>-->
<!--                            <el-input size="small" type="Number" v-model="form.refundStatus.value" min="7" max="30" class="smallInput"></el-input>-->
<!--                            {{$t('m.setting.form.shouhou')}}-->
<!--                        </div>-->
<!--                        <div class="form-tips" style="margin-top: 10px;"> {{$t('m.setting.formTips.zidongguanbishouhou')}}</div>-->
<!--                    </el-form-item>-->
<!--                </el-form>-->
<!--            </div>-->
<!--            <el-button class="saveBtn" @click="handleSave" :loading="loading">{{$t('m.pubilc.save')}}</el-button>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {getMiniProSetting,updateMiniProSettingBatch} from "request/api/setting.js"
    export default {
        name: "index",
        data(){
            return{
                form:{
                    shopping:{
                        value:null,
                        key:"SHOPPING_TROLLEY",
                    },
                    contact:{
                        value:null,
                        key:"CONTACT_CUSTOMER_SERVICE",
                    },
                    productDetal:{
                        value:null,
                        key:"PRODUCT_DETAILS_PAGE"
                    },
                    productCustom:{
                        value:null,
                        name:null,
                        key:"CUSTOM_CUSTOMER_SERVICE_NAME"
                    },
                    orderDetail:{
                        value:null,
                        key:"ORDER_DETAILS_PAGE",
                    },
                    orderDetailName:{
                        value:null,
                        name:null,
                        key:"ORDER_CUSTOM_CUSTOMER_SERVICE_NAME"
                    },

                    bottomLogo:{
                        value:null,
                        logoUrl:null,
                        key:"BOTTOM_LOGO",
                    },
                    goodOut:{
                        value:null,
                        key:"GOODS_OLD_OUT"
                    },
                    merchantBtn:{
                        value:null,
                        key:"DISPLAY_MERCHANT_BUTTON",
                    },
                    recommended:{
                        value:null,
                        key:"SHOW_RECOMMENDED_PRODUCTS",
                    },
                    productPinjia:{
                        value:null,
                        key:"DISPLAY_PRODUCT_EVALUATION",
                    },
                    haopinlv:{
                        value:null,
                        number:null,
                        key:"HOW_MANY_HIGH_PRAISE_SHOW_DATA",
                    },
                    shoppingBtn:{
                        value:null,
                        isName:null,
                        name:null,
                        key:"SHOW_THE_BUY_NOW_BUTTON",
                    },
                    sales:{
                    //    销量信息
                        sales: null,
                        value: null,
                        isManySales: null,
                        key:"DISPLAY_SALES_INFORMATION",
                    },
                    transaction:{
                        //成交记录
                        value:null,
                        key:"DISPLAY_TRANSACTION_RECORDS",
                    },
                    sharemini:{
                        //小程序分享
                        value:null,
                        prefix:null,
                        isShowAutoPrefix:null,
                        key:"SHOW_APPLET_SHARING",
                    },
                    SharingPersonalInfo:{
                        style:null,
                        value:null,
                        isSharingPersonalInfo:null,
                        key:"DISPLAY_POSTER_SHARING",
                    },
                    fukuanTime:{
                        value:null,
                        key:"AUTOMATIC_ORDER_CANCELLATION",
                    },
                    promrt:{
                        value:null,
                        key:"WHETHER_PROMPT_POPOVER",
                    },
                    confirmation:{
                        value:null,
                        key:"AUTOMATIC_CONFIRMATION_RECEIPT",
                    },
                    buyer:{
                        value:null,
                        key:"THE_BUYER_RETURNS",
                    },
                    beginAmount:{
                    //    邮费设置
                        value:null,
                        beginAmount:null,
                        fee:null,
                        key:"DELIVERY_FEE",
                    },
                    selllerAddress:{
                    //    收货地址
                        value:null,
                        name:null,
                        phone:null,
                        address:null,
                        key:"SELLER_ADDRESS",
                    },
                    acquirenti:{
                        //    买家退货
                        value:null,
                        key:"THE_BUYER_RETURNS",
                    },
                    commento:{
                        //    自动评论
                        value:null,
                        key:"AUTOMATIC_COMMENT_TIME",
                    },
                    //填写物流
                    logistica:{
                        value:null,
                        key:"WAIT_CUSTOMER_LOGISTICS_TIMEOUT_TIME",
                    },
                //    售后
                    refundStatus:{
                        value:null,
                        key:"REFUND_TIMEOUT",
                    }

                },
                settingList:[],
                loading:false,
            }
        },
        mounted(){
            // this.getMiniProSetting()
        },
        methods:{
            getMiniProSetting(){
                let _this = this
                getMiniProSetting().then(res=>{
                    if(res.code==200){
                        _this.settingList = res.data
                        if(_this.settingList&&_this.settingList.length>0){
                            _this.settingList.forEach(item=>{
                                for(let key in _this.form){
                                    if(_this.form[key].key==item.key){
                                        _this.form[key].value = item.property.value
                                        if(_this.form[key].logoUrl==null){
                                            _this.form[key].logoUrl = item.property.logoUrl
                                        }
                                        if(_this.form[key].name==null){
                                            _this.form[key].name = item.property.name
                                        }
                                        if(_this.form[key].isName==null){
                                            _this.form[key].isName = item.property.isName
                                        }
                                        if(_this.form[key].sales==null){
                                            _this.form[key].sales = item.property.sales
                                        }
                                        if(_this.form[key].isManySales==null){
                                            _this.form[key].isManySales = item.property.isManySales
                                        }
                                        if(_this.form[key].number==null){
                                            _this.form[key].number = item.property.number
                                        }
                                        if(_this.form[key].isShowAutoPrefix==null){
                                            _this.form[key].isShowAutoPrefix = item.property.isShowAutoPrefix
                                        }
                                        if(_this.form[key].prefix==null){
                                            _this.form[key].prefix = item.property.prefix
                                        }
                                        if(_this.form[key].style==null){
                                            _this.form[key].style = item.property.style
                                        }
                                        if(_this.form[key].isSharingPersonalInfo==null){
                                            _this.form[key].isSharingPersonalInfo = item.property.isSharingPersonalInfo
                                        }
                                        if(_this.form[key].beginAmount==null){
                                            _this.form[key].beginAmount = item.property.beginAmount
                                        }
                                        if(_this.form[key].fee==null){
                                            _this.form[key].fee = item.property.fee
                                        }
                                        if(_this.form[key].address==null){
                                            _this.form[key].address = item.property.address
                                        }
                                        if(_this.form[key].phone==null){
                                            _this.form[key].phone = item.property.phone
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            },
            handleContact(){
                if(this.form.contact.value==0){
                    this.form.productDetal.value = 0
                    this.form.orderDetail.value = 0
                }
            },
            handleAutoPrefix(){
                console.log(497,this.form.sharemini.isShowAutoPrefix)
            },
            handleisName(e){
                console.log(e)
                this.form.shoppingBtn.isName = e
                console.log(499,this.form.shoppingBtn.isName)
            },
            //上传图片
            upload(){
                let value = null
                let _this = this
                this.$materialSelector({
                    value: new Array(value),
                    selectNumber:1,
                    confirm:function (urls) {
                        _this.imgPickerHandle(urls)
                    },
                    close:function () {}
                })
            },
            //重新设置
            returnUpload(){
                this.form.bottomLogo.logoUrl = ""
                this.upload()
            },
            //图片回调
            imgPickerHandle(imgs) {
                this.form.bottomLogo.logoUrl = this.$imageUrl+imgs[imgs.length-1]
            },
            //移除图片
            deleteMateria(){
                this.form.bottomLogo.logoUrl = ""
            },
            handleSave(){
                // alert('保存')
                let _this = this
                _this.loading = true
                if(_this.settingList.length>0){
                    _this.settingList.forEach(item=>{
                        for(let key in _this.form){
                            if(item.key==_this.form[key].key){
                                item.property.value = _this.form[key].value
                                if(_this.form[key].logoUrl!=null){
                                    item.property.logoUrl = _this.form[key].logoUrl
                                }
                                if(_this.form[key].name!=null){
                                    item.property.name = _this.form[key].name
                                }
                                if(_this.form[key].isName!=null){
                                    item.property.isName = _this.form[key].isName
                                }
                                if(_this.form[key].sales!=null){
                                     item.property.sales = _this.form[key].sales
                                }
                                if(_this.form[key].isManySales!=null){
                                     item.property.isManySales = _this.form[key].isManySales
                                }
                                if(_this.form[key].number!=null){
                                    item.property.number = _this.form[key].number
                                }
                                if(_this.form[key].isShowAutoPrefix!=null){
                                    item.property.isShowAutoPrefix = _this.form[key].isShowAutoPrefix
                                }
                                if(_this.form[key].prefix!=null){
                                    item.property.prefix =  _this.form[key].prefix
                                }
                                if(_this.form[key].style!=null){
                                    item.property.style = _this.form[key].style
                                }
                                if(_this.form[key].isSharingPersonalInfo!=null){
                                    item.property.isSharingPersonalInfo =  _this.form[key].isSharingPersonalInfo
                                }
                                if(_this.form[key].beginAmount!=null){
                                    item.property.beginAmount = _this.form[key].beginAmount
                                }
                                if(_this.form[key].fee!=null){
                                    item.property.fee =  _this.form[key].fee
                                }
                                if(_this.form[key].address!=null){
                                     item.property.address = _this.form[key].address
                                }
                                if(_this.form[key].phone!=null){
                                     item.property.phone = _this.form[key].phone
                                }
                            }
                        }
                    })
                }
                updateMiniProSettingBatch(_this.settingList).then(res=>{
                    _this.loading = false
                    if(res.code==200){
                        _this.$notify.success({
                            title: this.$t('m.pubilc.tips'),
                            message: this.$t('m.pubilc.tip_success'),
                        });
                        _this.getMiniProSetting()
                    }else{
                        _this.$notify.error({
                            title: this.$t('m.pubilc.tips'),
                            message: res.message,
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";
    .table_box {
        background: #fff;
        margin: auto;
        border-radius: 8px;
        height: 100%;
        width: 100%;
        overflow: scroll;
    }
    ::-webkit-scrollbar{
        display: none;
    }
    .table_box_content{
        padding: 15px;
    }
    .table-title{
        background: #F6F6F6;
        height: 32px;
        border-radius: 4px;
        border-left: 3px solid @color-primary;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .keyword {
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        padding-left: 16px;
    }
    .form-tips{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }
    .hrefTips{
        color: @color-primary;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
    }
    /deep/ .el-radio__label,
    /deep/ .el-checkbox__label{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .inputClass{
        margin-top: -10px;
        ::placeholder{
            color: #666;
        }
        /deep/ input{
            width: 220px;
            background: #F6F6F6;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
        }
    }
    .smallInput{
        width: 100px;
        /deep/ input{
            width: 100px;
            border-radius: 4px;
        }
    }
    .saveBtn{
        width: fit-content;
        padding: 0 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        background: @color-primary;
        border-radius: 4px;
        color: #fff;
        margin: 40px auto;
        cursor:pointer;
        display: block;
    }
    .editor_goods_upload_box {
        position: relative;
    }
    .defalut-icon{
        background: #eee!important;
        border-radius: 50% 50%;
        box-sizing: border-box;
        padding-top: 7px;
        img{
            width: 23px;
            height: 21px;
        }
    }
    .images-delete {
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: white;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        position: relative;
        left: 0;
        top: 0;
    }

    .editor_goods_upload_box.hover:hover .images-delete {
        display: block;
    }
    .editor_goods_upload_box {
        display: block;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        color: @color-primary;
        margin: 0 10px 0 0;
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        background-color: #fff;
        font-size: 12px;
        background-repeat: no-repeat;
    }
    .flexRadio{
        display: flex;
        align-items: center;
        height: 40px;
        /deep/ .el-radio{
            margin-right: 10px;
        }
    }
</style>
